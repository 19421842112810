import React from "react"
import SEO from "../components/Seo"
import Container from "../components/Container"
import Belt from "../components/Belt"
import { Title, Quote } from "../components/typography"
import {
  Container as StaticBlockContainer,
  StaticBlock,
} from "../components/typography/StaticBlock"
import { FigureContainer, Figure } from "../components/Figure"
import globuli_bw from "../images/belt/globuli_bw.jpg"
import globuli from "../images/belt/globuli.jpg"
import bicicle from "../images/illustrations/bicicle.jpg"
import wheelChair from "../images/illustrations/wheelChair.jpg"
import boyDog from "../images/illustrations/boyDog.jpg"
import girlMother from "../images/illustrations/girlMother.jpg"
import ganzheitlich from "../images/illustrations/ganzheitlich.jpg"

const HomeopathiePage = () => (
  <>
    <SEO
      title="Homöopathie"
      keywords={[
        "Homöopathie",
        "Modernste und durchdachteste Methode",
        "Ökonomisch zu behandeln",
        "Mahatma Ghandi",
        "Samuel Hahnemann",
        "Similia similibus curentur",
        "Ähnliches möge mit ähnlichem geheilt werden",
        "Klassische Homöopathie",
        "Ähnlichkeitsprinzip",
        "Individualität",
        "Konstitution",
        "Individuelle Reaktion",
        "Homöopathische Arzneien",
        "Ganzheitlich",
        "Körper und Geist",
        "Natürliche Rohstoffe",
      ]}
      description="Die Homöopathie wurde vor 200 Jahren von Samuel Hahnemann entdeckt und gegründet. Die homöopathischen Arzneien werden nach dem Ähnlichkeitsprinzip (similia similibus curentur) und nach der Individualität jedes Patienten verschrieben. Bei der Behandlung wird der Mensch als ganzes und nicht die einzelnen Krankheiten separat behandelt."
    />
    <Belt leftImage={globuli_bw} rightImage={globuli} />
    <Container>
      <Quote cite="Mahatma Ghandi">
        Die Homöopathie ist die modernste und durchdachteste Methode, um Kranke
        ökonomisch und gewaltlos zu behandeln.
      </Quote>
      <StaticBlockContainer>
        <StaticBlock>
          <Title>DIE HOMÖOPATHIE</Title>
          <p>
            Die Homöopathie wurde vor mehr als 200 Jahren vom deutschen Arzt
            Samuel Hahnemann (1755-1843) gegründet und entdeckt. Sie ist eine
            eigenständige Wissenschaft mit genau beschriebenen
            Gesetzmässigkeiten und Regeln.
          </p>
          <p>
            Hahnemann stiess im Jahr 1790 durch einen Selbstversuch mit
            Chinarinde auf das Heilungsgesetzt{" "}
            <em>similia similibus curentur</em> (ähnliches möge mit ähnlichem
            geheilt werden). Dies ist der wichtigste Grundsatz der Homöopathie
            und wurde interessanterweise auch schon von Hippokrates (460 v.
            Chr.) und Paracelsuss (1500) beschrieben. Als Arzt, Apotheker und
            Chemiker forschte Hahnemann akribisch sein ganzes Leben an den
            Gesetzmässigkeiten weiter und legte die Fundamente für die
            klassische Homöopathie. Er entdeckte damit eine natürliche und
            ganzheitliche Behandlungsmethode, um sanft, schnell und dauerhafte
            Heilung zu bringen. Die Homöopathie hat sich schon während seiner
            Lebzeit rasch in ganz Europa und den USA verbreitet und wird heute
            weltweit erfolgreich eingesetzt.
          </p>
          <h3>Die Grundsätze</h3>
          <p>
            Die homöopathischen Arzneien werden nach dem Ähnlichkeitsprinzip
            verschrieben, mit Berücksichtigung der Individualität sowie
            Ganzheitlichkeit jeder einzelnen Person.
          </p>
        </StaticBlock>
        <StaticBlock>
          <Title>
            DAS ÄHNLICHKEITS
            <wbr />
            &shy;PRINZIP
          </Title>
          <p>
            Hahnemann hat beobachtet, dass Arzneien, welche beim gesunden
            Menschen Symptome hervorrufen, diese beim Kranken heilen können und
            ist so auf das Heilungsgesetz <em>similia similibus curentur</em>{" "}
            (ähnliches möge mit ähnlichem geheilt werden) gestossen. Das
            Ähnlichkeitsprinzip ist auch namensgebend für die Homöopathie,
            welche aus dem griechischen <em>hómoios</em> = gleich, gleichartig
            und <em>páthos</em> = Leiden stammt.
          </p>
          <FigureContainer>
            <Figure src={bicicle} alt="Gesunder Mensch">
              SYMPTOM BEIM GESUNDEN
            </Figure>
            <Figure src={wheelChair} alt="Kranker Mensch">
              HEILUNG BEIM KRANKEN
            </Figure>
          </FigureContainer>
        </StaticBlock>
        <StaticBlock>
          <Title>INDIVIDUALITÄT</Title>
          <p>
            Jeder von uns hat eine einzigartige genetische Veranlagung, welche
            unsere Konstitution ausmacht. Abhängig von unserer Konstitution
            reagieren wir individuell auf Umwelt und Krankheiten. Bei der
            homöopathischen Verschreibung wird diese individuelle Reaktion
            berücksichtigt und die Arznei wird nicht aufgrund der Diagnose,
            sondern gemäss den gezeigten Symptomen verschrieben. Deshalb kann es
            sein, dass zwei Patienten mit der selben Krankheit unterschiedliche
            Arzneien bekommen.
          </p>
          <p>
            Am besten sieht man die individuelle Reaktion in der Familie, wenn
            z.B. zwei Geschwister an dem selben Husteninfekt erkranken. Der
            Bruder mag mit hohem Fieber, einem trockenen, bellenden Husten
            reagieren und hat lieber seine Ruhe. Die Schwester mag hingegen nur
            mildes Fieber mit leichtem Husten haben und ist anhänglich und
            weinerlich. Obwohl beide den selben Infekt haben, zeigen sie ganz
            andere Symptome und bekommen deshalb auch unterschiedliche
            homöopathische Arzneien, in unserem Beispiel <em>Belladonna</em> und{" "}
            <em>Pulsatilla</em>.
          </p>
          <FigureContainer>
            <Figure src={boyDog} alt="Junge">
              PLÖTZLICH HOCH FIEBER
              <br /> BELLENDER HUSTEN
              <br /> WILL SEINE RUHE
              <br /> <em>- Belladonna -</em>
            </Figure>
            <Figure src={girlMother} alt="Mädchen">
              MILDES FIEBER
              <br />
              LEICHTER HUSTEN
              <br /> ANHÄNGLICH, WEINERLICH
              <br /> <em>- Pulsatilla -</em>
            </Figure>
          </FigureContainer>
        </StaticBlock>
        <StaticBlock>
          <Title>GANZHEITLICH</Title>
          <p>
            Die Homöoapthie ist eine ganzheitliche Behandlungsmethode, aber was
            heisst das genau? Ganzeitlich, heisst, dass Körper und Geist als
            eine Einheit gesehen werden, welche miteinander verbunden sind und
            sich gegenseitig beeinflussen. In der Homöopathie wird der Mensch
            als Ganzes, und nicht die einzelnen Krankheiten getrennt (z.B.
            Bluthochdruck, Hautausschlag, Depression, usw.), behandelt. Aus
            ganzheitlicher Sicht ist es bei der Genesung wichtig, dass das
            Wohlbefinden auf allen Ebenen, also auf der körperlichen sowie auf
            der geistigen, wieder hergestellt wird. Wenn z.B. der Hautausschlag
            bessert, aber die Depression sich verschlimmert, dann ist dies kein
            wünschenswerter Verlauf.
          </p>
          <FigureContainer>
            <Figure src={ganzheitlich} alt="Ganzheitlich" />
          </FigureContainer>
        </StaticBlock>
        <StaticBlock>
          <Title>DIE HOMÖOPATHISCHEN ARZNEIEN</Title>
          <p>
            Die homöopathischen Arzneien (Globuli) werden aus natürlichen
            Rohstoffen, welche aus dem mineralischen, pflanzlichen und
            tierischen Bereich stammen, hergesellt. Sie werden nach den
            Vorschriften von Swissmedic und des HAB (Homöopathisches Arzneibuch)
            hergestellt. Es gibt mehr als 2’000 unterschiedliche homöopathische
            Mittel. Die Mittelwahl, Dosierung und Wiederholung ist individuell.
          </p>
        </StaticBlock>
      </StaticBlockContainer>
    </Container>
  </>
)

export default HomeopathiePage
